import React from "react";

function Table() {
  return (
    <div className="table">
      <ul>
        <li className="red">Profile</li>
        <li className="green">Projects</li>
        <li className="blue">Snack</li>
      </ul>
    </div>
  );
}

export default Table;
